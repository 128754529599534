header
  position fixed
  top 0
  left 0
  z-index 100
  width 100%
  padding .5em 1em
  transition .3s ease
  &.fixed
  &.active
    border-bottom 2px solid $main-color
    background rgba($white, .9)

.header-container
  display flex
  justify-content space-between
  align-items center

.header-logo
  width 140px
  height 100%
  margin 0
  +tb()
    width 120px

.header-nav
  display flex
  justify-content space-between
  align-items center
  +tb()
    position fixed
    top 66px
    left 100%
    justify-content flex-start
    flex-direction column
    width 100%
    height 100%
    background rgba($white, .9)
    transition .3s ease
    &.active
      left 0

.header-nav-item
  hover()
  margin 0 8px
  padding 0 8px
  &.contact
    padding 8px
    border 1px solid $main-color
    border-radius 4px
    background $main-color
    color white
    font-weight 800
    transition .3s ease
    &:hover
      background $white
      color $main-color
      opacity 1
  +tb()
    padding 1em
    width 100%
    text-align center
    &+&
      border-top 1px solid $border-color
    &.contact
      padding 1em
      border-radius 0