html
body
  width 100%


body
  font-size 16px
  font-family $maru
  // mincho
  // font-family $serif
  line-height 1.6
  position relative
  color $text-color
  background $background-color
  word-wrap break-word
  -webkit-text-size-adjust 100%
  -webkit-overflow-scrolling touch
  -webkit-font-smoothing antialiased
  +tb()
    font-size 14px

h1
h2
h3
h4
h5
h6
  font-weight normal

a
  text-decoration none

ul
  padding-left 1.5em
  margin 0
