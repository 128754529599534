html,
body {
  width: 100%;
}
body {
  font-size: 16px;
  font-family: "ヒラギノ丸ゴ Pro W4", "ヒラギノ丸ゴ Pro", "Hiragino Maru Gothic Pro", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "HG丸ｺﾞｼｯｸM-PRO", "HGMaruGothicMPRO";
  line-height: 1.6;
  position: relative;
  color: #555;
  background: #fff;
  word-wrap: break-word;
  -webkit-text-size-adjust: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
}
@media only screen and (max-width: 960px) {
  body {
    font-size: 14px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
a {
  text-decoration: none;
}
ul {
  padding-left: 1.5em;
  margin: 0;
}
*,
::before,
::after {
  background-repeat: no-repeat;
  box-sizing: inherit;
}
::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit;
}
html {
  box-sizing: border-box;
  cursor: default;
  font-family: sans-serif;
  line-height: 1.5;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
article,
aside,
footer,
header,
nav,
section {
  display: block;
}
body {
  margin: 0;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
figcaption,
figure,
main {
  display: block;
}
figure {
  margin: 1em 40px;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
nav ol,
nav ul {
  list-style: none;
}
pre {
  font-family: monospace, monospace;
  font-size: 1em;
}
a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  color: #555;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: inherit;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
dfn {
  font-style: italic;
}
mark {
  background-color: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
::-moz-selection {
  background-color: #b3d4fc;
  color: #000;
  text-shadow: none;
}
::selection {
  background-color: #b3d4fc;
  color: #000;
  text-shadow: none;
}
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}
audio,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
img {
  border-style: none;
}
svg {
  fill: currentColor;
}
svg:not(:root) {
  overflow: hidden;
}
table {
  border-collapse: collapse;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
}
button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
progress {
  display: inline-block;
  vertical-align: baseline;
}
textarea {
  overflow: auto;
  resize: vertical;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
details,
menu {
  display: block;
}
summary {
  display: list-item;
}
canvas {
  display: inline-block;
}
template {
  display: none;
}
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
[hidden] {
  display: none;
}
[aria-busy="true"] {
  cursor: progress;
}
[aria-controls] {
  cursor: pointer;
}
[aria-hidden="false"][hidden] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}
[aria-hidden="false"][hidden]:focus {
  clip: auto;
}
[aria-disabled] {
  cursor: default;
}
.copyright {
  padding: 2em 5px 1em;
  background: #ffa500;
  color: #fff;
  font-size: 12px;
  text-align: center;
}
header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  padding: 0.5em 1em;
  transition: 0.3s ease;
}
header.fixed,
header.active {
  border-bottom: 2px solid #ffa500;
  background: rgba(255,255,255,0.9);
}
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-logo {
  width: 140px;
  height: 100%;
  margin: 0;
}
@media only screen and (max-width: 960px) {
  .header-logo {
    width: 120px;
  }
}
.header-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 960px) {
  .header-nav {
    position: fixed;
    top: 66px;
    left: 100%;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.9);
    transition: 0.3s ease;
  }
  .header-nav.active {
    left: 0;
  }
}
.header-nav-item {
  transition: opacity 0.2s ease-out;
  cursor: pointer;
  margin: 0 8px;
  padding: 0 8px;
}
.header-nav-item:hover {
  opacity: 0.7;
}
@media only screen and (max-width: 960px) {
  .header-nav-item:hover {
    opacity: 1;
  }
}
.header-nav-item.contact {
  padding: 8px;
  border: 1px solid #ffa500;
  border-radius: 4px;
  background: #ffa500;
  color: #fff;
  font-weight: 800;
  transition: 0.3s ease;
}
.header-nav-item.contact:hover {
  background: #fff;
  color: #ffa500;
  opacity: 1;
}
@media only screen and (max-width: 960px) {
  .header-nav-item {
    padding: 1em;
    width: 100%;
    text-align: center;
  }
  .header-nav-item+.header-nav-item {
    border-top: 1px solid #dadada;
  }
  .header-nav-item.contact {
    padding: 1em;
    border-radius: 0;
  }
}
.loading {
  pointer-events: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  background-repeat: repeat;
  background-size: 50px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fade-out 1s ease-out 2s forwards;
}
.loading-logo {
  position: relative;
  pointer-events: none;
  width: 270px;
  line-height: 1.4;
}
@media only screen and (max-width: 560px) {
  .loading-logo {
    width: 220px;
    padding: 240px 0;
    margin: 0 auto;
  }
}
.loading-logo .logo-1 {
  fill: none;
  stroke: #ffa500;
  stroke-dasharray: 100px 100px;
  animation: logo-stroke 1.2s cubic-bezier(0.71, 0.01, 1, 0.46) forwards;
  stroke-width: 0.5;
}
.loading-logo .logo-2 {
  fill: none;
  stroke: #555;
  stroke-dasharray: 100px 100px;
  animation: logo-stroke2 1.2s cubic-bezier(0.71, 0.01, 1, 0.46) forwards;
  stroke-width: 0.5;
}
@-moz-keyframes logo-stroke {
  0% {
    stroke-dashoffset: 100px;
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #ffa500;
  }
}
@-webkit-keyframes logo-stroke {
  0% {
    stroke-dashoffset: 100px;
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #ffa500;
  }
}
@-o-keyframes logo-stroke {
  0% {
    stroke-dashoffset: 100px;
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #ffa500;
  }
}
@keyframes logo-stroke {
  0% {
    stroke-dashoffset: 100px;
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #ffa500;
  }
}
@-moz-keyframes logo-stroke2 {
  0% {
    stroke-dashoffset: 100px;
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #555;
  }
}
@-webkit-keyframes logo-stroke2 {
  0% {
    stroke-dashoffset: 100px;
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #555;
  }
}
@-o-keyframes logo-stroke2 {
  0% {
    stroke-dashoffset: 100px;
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #555;
  }
}
@keyframes logo-stroke2 {
  0% {
    stroke-dashoffset: 100px;
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #555;
  }
}
@-moz-keyframes fade-out {
  0% {
    opacity: 1;
    transform-origin: 0;
  }
  100% {
    opacity: 0;
    pointer-events: none;
  }
}
@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
    transform-origin: 0;
  }
  100% {
    opacity: 0;
    pointer-events: none;
  }
}
@-o-keyframes fade-out {
  0% {
    opacity: 1;
    transform-origin: 0;
  }
  100% {
    opacity: 0;
    pointer-events: none;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
    transform-origin: 0;
  }
  100% {
    opacity: 0;
    pointer-events: none;
  }
}
.main-container {
  padding-bottom: 80px;
  background: #f3efe4;
}
section {
  padding: 12px;
}
.section-heading {
  display: table;
  margin: 80px auto 24px;
  padding: 0 8px;
  border-bottom: 2px solid #ffa500;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.05em;
}
@media only screen and (max-width: 960px) {
  .section-heading {
    margin-top: 40px;
    font-size: 18px;
  }
}
.section-inner {
  max-width: 1200px;
  margin: auto;
}
.sp-menu {
  display: none;
}
@media only screen and (max-width: 960px) {
  .sp-menu {
    display: block;
  }
}
.ham {
  cursor: pointer;
  transition: transform 400ms;
  user-select: none;
}
.sp-menu.active .hamRotate {
  transform: rotate(45deg);
}
.hamRotate180.active {
  transform: rotate(180deg);
}
.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: #000;
  stroke-width: 5.5;
  stroke-linecap: round;
}
.ham .top {
  stroke-dasharray: 40 160;
}
.ham .middle {
  stroke-dasharray: 40 142;
  transform-origin: 50%;
  transition: transform 400ms;
}
.ham .bottom {
  stroke-dasharray: 40 85;
  transform-origin: 50%;
  transition: transform 400ms, stroke-dashoffset 400ms;
}
.sp-menu.active .top {
  stroke-dashoffset: -64px;
}
.sp-menu.active .middle {
  transform: rotate(90deg);
}
.sp-menu.active .bottom {
  stroke-dashoffset: -64px;
}
.align-center {
  text-align: center;
}
.clearfix::after {
  content: "";
  display: block;
  clear: both;
}
.main-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  background: url("../images/main.jpg") center bottom/cover;
}
@media only screen and (max-width: 960px) {
  .main-img {
    height: 400px;
  }
}
.main-text {
  color: #ffa500;
  font-size: 40px;
  font-weight: 800;
  text-align: center;
  letter-spacing: 0.05em;
  line-height: 1;
}
.main-text span {
  color: #555;
  font-size: 16px;
  font-weight: 400;
}
@media only screen and (max-width: 960px) {
  .main-text {
    font-size: 32px;
  }
  .main-text span {
    font-size: 14px;
  }
}
table {
  width: 100%;
  margin: 1em 0;
  border: 1px solid #dadada;
  background: #fff;
}
table tr {
  border-top: 1px solid #dadada;
}
table th {
  width: 168px;
  background-color: rgba(255,165,0,0.2);
  text-align: left;
}
table th,
table td {
  padding: 0.5em;
}
table th+td {
  border-left: 1px solid #dadada;
}
table a {
  transition: opacity 0.2s ease-out;
  cursor: pointer;
  color: #ffa500;
  text-decoration: underline;
}
table a:hover {
  opacity: 0.7;
}
@media only screen and (max-width: 960px) {
  table a:hover {
    opacity: 1;
  }
}
@media only screen and (max-width: 960px) {
  table th,
  table td {
    display: block;
    width: 100%;
    padding: 0.5em;
  }
  table td {
    padding: 1.5em 0.5em;
  }
  table th+td {
    border-left: none;
    border-top: 1px solid #dadada;
  }
}
.to-contact {
  display: table;
  margin: 40px auto;
  padding: 0.5em 2em;
  border: 1px solid #ffa500;
  border-radius: 4px;
  background: #ffa500;
  color: #fff;
  font-size: 18px;
  font-weight: 800;
  transition: 0.3s ease;
}
.to-contact:hover {
  background: #fff;
  color: #ffa500;
}
@media only screen and (max-width: 960px) {
  .to-contact {
    font-size: 16px;
  }
}
