.sp-menu
  display none
  +tb()
    display block
.ham
  cursor pointer
  transition transform 400ms
  user-select none

.sp-menu.active .hamRotate
  transform rotate(45deg)

.hamRotate180.active
  transform rotate(180deg)

.line
  fill none
  transition stroke-dasharray 400ms, stroke-dashoffset 400ms
  stroke #000
  stroke-width 5.5
  stroke-linecap round
.ham .top
  stroke-dasharray 40 160

.ham .middle
  stroke-dasharray 40 142
  transform-origin 50%
  transition transform 400ms

.ham .bottom
  stroke-dasharray 40 85
  transform-origin 50%
  transition transform 400ms, stroke-dashoffset 400ms

.sp-menu.active .top
  stroke-dashoffset -64px

.sp-menu.active .middle
  //stroke-dashoffset -20px
  transform rotate(90deg)

.sp-menu.active .bottom
  stroke-dashoffset -64px

