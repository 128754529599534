.main-img
  display flex
  justify-content center
  align-items center
  height 600px
  background url(../images/main.jpg) center bottom / cover
  +tb()
    height 400px

.main-text
  color $main-color
  font-size 40px
  font-weight 800
  text-align center
  letter-spacing .05em
  line-height 1
  span
    color $text-color
    font-size 16px
    font-weight 400
  +tb()
    font-size 32px
    span
      font-size 14px
