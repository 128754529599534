.loading
  pointer-events none
  position fixed
  z-index 1000
  top 0
  right 0
  bottom 0
  left 0
  background #fff
  background-repeat repeat
  background-size 50px 50px
  display flex
  justify-content center
  align-items center
  animation fade-out 1s ease-out 2s forwards

.loading-logo
  position relative
  pointer-events none
  width 270px
  line-height 1.4
  +sp()
    width 220px
    padding 240px 0
    margin 0 auto
  .logo-1
    fill none
    stroke $main-color
    stroke-dasharray 100px 100px
    animation logo-stroke 1.2s cubic-bezier(.71,.01,1,.46) forwards
    stroke-width .5
  .logo-2
    fill none
    stroke #555
    stroke-dasharray 100px 100px
    animation logo-stroke2 1.2s cubic-bezier(.71,.01,1,.46) forwards
    stroke-width .5

@keyframes logo-stroke
  0%
    stroke-dashoffset 100px
    fill transparent
  100%
    stroke-dashoffset 0
    fill $main-color

@keyframes logo-stroke2
  0%
    stroke-dashoffset 100px
    fill transparent
  100%
    stroke-dashoffset 0
    fill #555

@keyframes fade-out
  0%
    opacity 1
    transform-origin: 0
  100%
    opacity 0
    pointer-events none
