section
  padding 12px

.section-heading
  display table
  margin 80px auto 24px
  padding 0 8px
  border-bottom 2px solid $main-color
  font-size 24px
  font-weight 700
  text-align center
  letter-spacing .05em
  +tb()
    margin-top 40px
    font-size 18px

.section-inner
  max-width 1200px
  margin auto
