table
  width 100%
  margin 1em 0
  border 1px solid $border-color
  background $white
  tr
    border-top 1px solid $border-color
  th
    width 168px
    background-color rgba($main-color, .2)
    text-align left
  th,
  td
    padding .5em
  th+td
    border-left 1px solid $border-color
  a
    hover()
    color $main-color
    text-decoration underline
  +tb()
    th,
    td
      display block
      width 100%
      padding .5em
    td
      padding 1.5em .5em
    th+td
      border-left none
      border-top 1px solid $border-color